<template>
  <section class="monitor">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <div class="title">
        <h2>{{ options.title }}</h2>
        <div class="line"></div>
        <p>{{ options.en }}</p>
        <!-- <p class="tit">{{ options.title1 ? options.title1 : options.title }}</p> -->
      </div>
      <el-row :gutter="50">
        <el-col :span="12"><ArticleContent :data="options.content" class="information-content" /></el-col>
        <el-col :span="12"><el-image :src="options.img"></el-image></el-col>
      </el-row>
      <div class="pic" v-if="options.title2">
        <div class="title">
          <h2>{{ options.title2 }}</h2>
          <div class="line"></div>
          <p>{{ options.en2 }}</p>
          <p class="tit">{{ options.title1 ? options.title1 : options.title2 }}</p>
        </div>
        <el-row :gutter="50">
          <el-col :span="12"><ArticleContent :data="options.content2" class="information-content" /></el-col>
          <el-col :span="12"><el-image :src="options.img2"></el-image></el-col>
        </el-row>
      </div>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/2023/product/导航图.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {title: '', content: []}
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      switch (id) {
        case '1':
          this.options = this.$store.getters.getMonitor1 && JSON.parse(JSON.stringify(this.$store.getters.getMonitor1))
          break
        case '2':
          this.options = this.$store.getters.getMonitor2 && JSON.parse(JSON.stringify(this.$store.getters.getMonitor2))
          break
        default:
          this.options = this.$store.getters.getMonitor1 && JSON.parse(JSON.stringify(this.$store.getters.getMonitor1))
          break
      }
    }
  }
}
</script>

<style scoped>
.monitor {background-color: #ecf0f5; padding-bottom: 1rem;}
.monitor .title {text-align: center; padding: 1rem 0 5rem; line-height: 2; font-size: 2rem; color: #666;}
.monitor .title .line {width: 2em; height: 2px; background-color: #e67700; margin: 0 auto;}
.monitor .title  .tit {font-size: 1.2rem;}
.information-content {padding-top: 2rem; border-top: 1px solid #999;}
.pic {margin-top: 1rem; border-top: 1px solid #e67700;}
</style>